.rc-time-picker {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}
.rc-time-picker * {
  box-sizing: border-box;
}
.rc-time-picker-clear {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 3px;
  margin: 0;
}
.rc-time-picker-clear-icon:after {
  content: "x";
  font-size: 12px;
  font-style: normal;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  height: 20px;
  width: 20px;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.rc-time-picker-clear-icon:hover:after {
  color: #666;
}
.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rc-time-picker-input[disabled] {
  color: #ccc;
  background: #f7f7f7;
  cursor: not-allowed;
}
.rc-time-picker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}
.rc-time-picker-panel * {
  box-sizing: border-box;
}
.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}
.rc-time-picker-panel-narrow {
  max-width: 113px;
}
.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
}
.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}
.rc-time-picker-panel-input-invalid {
  border-color: red;
}
.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
}
.rc-time-picker-panel-select-active {
  overflow-y: auto;
}
.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}
.rc-time-picker-panel-select:last-child {
  border-right: 0;
}
.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}
.rc-time-picker-panel-select li {
  list-style: none;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}
li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}
li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;

  -webkit-flex-direction: row;

          flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-Months {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4A90E2;
  color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.rbt {
  outline: none;
}

.rbt-menu {
  margin-bottom: 2px;
}

.rbt-menu > li a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbt-menu > li a:focus {
  outline: none;
}

.rbt-menu-pagination-option {
  text-align: center;
}

.rbt .rbt-input-main::-ms-clear {
  display: none;
}

.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
  height: auto;
}

.rbt-input-multi.focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  border-color: #66afe9;
  outline: 0;
}

.rbt-input-multi.form-control[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}

.rbt-input-multi input::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: #999;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #999;
}

.rbt-input-multi .rbt-input-wrapper {
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}

.rbt-input-multi .rbt-input-main {
  height: 20px;
  margin: 1px 0 4px;
}

.rbt-input-multi .rbt-input-hint-container {
  display: inline-block;
}

.rbt-input-multi.input-lg .rbt-input-main, .rbt-input-multi.form-control-lg .rbt-input-main {
  height: 24px;
}

.rbt-input-multi.input-sm .rbt-input-main, .rbt-input-multi.form-control-sm .rbt-input-main {
  height: 18px;
}

.rbt-close {
  z-index: 1;
}

.rbt-close-lg {
  font-size: 24px;
}

.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 2px;
  color: #1f8dd6;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  padding: 4px 7px;
  position: relative;
}

.rbt-token-disabled {
  background-color: #ddd;
  color: #888;
  pointer-events: none;
}

.rbt-token-removeable {
  cursor: pointer;
  padding-right: 21px;
}

.rbt-token-active {
  background-color: #1f8dd6;
  color: #fff;
  outline: none;
  text-decoration: none;
}

.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
}

.rbt-loader {
  -webkit-animation: loader-animation 600ms infinite linear;
  animation: loader-animation 600ms infinite linear;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  border-top-color: #1f8dd6;
  display: block;
  height: 16px;
  width: 16px;
}

.rbt-loader-lg {
  height: 20px;
  width: 20px;
}

.rbt-aux {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}

.rbt-aux-lg {
  width: 46px;
}

.rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}

.has-aux .rbt-input {
  padding-right: 34px;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/* Input Groups */
.input-group > .rbt {
  -webkit-flex: 1;
  flex: 1 1;
}

.input-group > .rbt .rbt-input-hint-container {
  display: -webkit-flex;
  display: flex;
}

.input-group > .rbt .rbt-input-hint {
  z-index: 5;
}

.input-group > .rbt .rbt-aux {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Validation States */
.has-error .rbt-input-multi.focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-warning .rbt-input-multi.focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-success .rbt-input-multi.focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

@keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@-webkit-keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
  }
}

